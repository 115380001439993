// extracted by mini-css-extract-plugin
export var component = "WeatherModule-module--component--Pp26B";
export var wrapper = "WeatherModule-module--wrapper--AjMAu";
export var header = "WeatherModule-module--header--36WTL";
export var headerText = "WeatherModule-module--headerText--p3Ofo";
export var title = "WeatherModule-module--title--sJzCE";
export var description = "WeatherModule-module--description--2dtC-";
export var img = "WeatherModule-module--img--26vYV";
export var weatherText = "WeatherModule-module--weatherText--Izzjd";
export var weather = "WeatherModule-module--weather--8zJIf";
export var icon = "WeatherModule-module--icon--EQa6O";
export var text = "WeatherModule-module--text--G7dtt";
export var bottomText = "WeatherModule-module--bottomText--z7Wje";