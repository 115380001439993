import ModuleWrapper from "@municipio/gatsby-theme-basic/src/components/ModuleWrapper";
import { Button, FormInputField } from "@whitespace/components";
import { useComponentSize } from "@whitespace/gatsby-hooks";
import clsx from "clsx";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./NewsletterModule.module.css";

NewsletterModule.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.any,
  module: PropTypes.any,
};

export default function NewsletterModule({
  styles = defaultStyles,
  title,
  module: { modNewsletterOptions: { text, link: { url } } = {} },
  ...restProps
}) {
  const { t } = useTranslation();

  const componentRef = useRef(null);
  let { width: componentWidth } = useComponentSize(componentRef);
  const isCompact = componentWidth <= 340;

  const location = window.location.origin;
  const updatedUrl = url.split("/").slice(3).join("/");
  const urlForThankYouPage = location + "/" + updatedUrl;

  return (
    <ModuleWrapper
      as={"div"}
      styles={styles}
      className={clsx(styles.component, isCompact && styles.isCompact)}
      {...restProps}
    >
      <div ref={componentRef}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.text}>{text}</p>
        <Formik
          initialValues={{
            email: "",
          }}
        >
          <form
            name="fpren"
            action="https://newsletter.paloma.se//register/"
            method="get"
            className={styles.form}
          >
            <input type="hidden" name="distlistkey" value="153236" />
            <input type="hidden" name="gora" value="pren" />
            <input type="hidden" name="tacksida" value={urlForThankYouPage} />
            <input
              type="hidden"
              name="ignorethankspageavailabilitycheck"
              value="0"
            />
            <input
              type="hidden"
              name="termsguid"
              value="d39157e2-fe87-4226-8d10-afcbf1cb79de"
            />
            <div className={styles.formField}>
              <label className={styles.emailLabel}>{t("yourEmail")}</label>
              <FormInputField
                name={"email"}
                f
                type={"email"}
                inputProps={{
                  placeholder: t("emailExample"),
                }}
                required={true}
                className={styles.formInput}
              />
              <Button className={styles.button} type="submit">
                {t("subscribe")}
              </Button>
            </div>
            <div className={styles.formField}>
              <input required={true} type={"checkbox"} name={"haschecked"} />
              <label>
                {t("agreeTo")}
                <a
                  href="https://public.paloma.se/Consent/ReadConsent?termsguid=d39157e2-fe87-4226-8d10-afcbf1cb79de"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  {t("processingOfData")}
                </a>
              </label>
            </div>
          </form>
        </Formik>
      </div>
    </ModuleWrapper>
  );
}
