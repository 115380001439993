import { H } from "@jfrk/react-heading-levels";
import { Grid } from "@municipio/gatsby-theme-basic/src/components";
import Excerpt from "@municipio/gatsby-theme-basic/src/components/Excerpt";
import {
  Card,
  CardContent,
  CardTitle,
  CardMedia,
} from "@whitespace/components";
import {
  usePageContext,
  usePages,
} from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import { getSiblings } from "@whitespace/gatsby-theme-wordpress-basic/src/utils/pageTree";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./RelatedLocationPages.module.css";

RelatedLocationPages.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  pageLimit: PropTypes.number,
};

export default function RelatedLocationPages({
  styles = defaultStyles,
  className,
  pageLimit = 12,
  ...restProps
}) {
  const { t } = useTranslation();
  const { stripHTML } = useHTMLProcessor();
  let {
    contentNode: {
      id,
      isFrontPage,
      location: { active: isLocation },
    },
  } = usePageContext();

  const allPages = usePages();
  const pageSiblings = getSiblings(allPages, id)
    .filter((page) => page.location.active)
    .slice(0, pageLimit);

  if (isFrontPage || !isLocation) return null;

  if (!pageSiblings.length) return null;

  return (
    <div className={clsx(styles.component, className)} {...restProps}>
      <H className={styles.title}>{t("Discover more")}</H>

      <Grid className={clsx(styles.list)} autoFit={true}>
        {pageSiblings.map((item, index) => {
          const { content, featuredImage, title, uri } = item;
          const excerpt = stripHTML(content);
          const image = featuredImage?.node;

          return (
            <Card link={{ uri }} className={clsx(styles.card)} key={index}>
              <CardContent className={clsx(styles.content)}>
                <CardTitle>{title}</CardTitle>
                {excerpt && (
                  <Excerpt text={excerpt} className={clsx(styles.excerpt)} />
                )}
              </CardContent>
              {image && <CardMedia image={image} />}
            </Card>
          );
        })}
      </Grid>
    </div>
  );
}
