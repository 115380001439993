import { css } from "@emotion/react";
import CookieConsent from "@whitespace/gatsby-plugin-cookie-consent/src/components";
import useCookieConsentSettings from "@whitespace/gatsby-plugin-cookie-consent/src/hooks/useCookieConsentSettings";
import Footer from "@whitespace/gatsby-theme-wordpress-basic/src/components/Footer";
import Header from "@whitespace/gatsby-theme-wordpress-basic/src/components/Header";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

import * as defaultStyles from "./SiteLayout.module.css";

SiteLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SiteLayout({
  children,
  className,
  styles = defaultStyles,
}) {
  const {
    contentNode: {
      title,
      isFrontPage,
      pageAppearance: { template } = {},
      pageThemeColor: { theme = [] } = {},
    } = {},
  } = usePageContext();

  const { position, active, strings } = useCookieConsentSettings();

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const themeProp = theme ? kebabCase(theme) : null;

  return (
    <>
      <Helmet
        title={title}
        titleTemplate={
          isFrontPage
            ? site.siteMetadata.title
            : `%s - ${site.siteMetadata.title}`
        }
        defaultTitle={site.siteMetadata.title}
        link={[
          {
            rel: `icon`,
            type: `image/svg+xml`,
            href: `/favicon.svg`,
          },
          {
            rel: `alternate icon`,
            href: `/favicon.ico`,
          },
          {
            rel: `mask-icon`,
            href: `/safari-pinned-tab.svg`,
            color: `#004071`,
          },
        ]}
        meta={[
          {
            name: "theme-color",
            content: "#fff",
            media: "(prefers-color-scheme: light)",
          },
        ]}
      />
      <div
        css={
          themeProp &&
          css`
            --page-theme-background: var(--brand-color-${themeProp});
          );
        `
        }
        className={clsx(styles.component, className)}
      >
        <CookieConsent
          position={position}
          cookieConsentSettings={strings}
          active={active}
        />
        <Header />
        <main className={styles.main} id="main">
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
}
