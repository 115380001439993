// extracted by mini-css-extract-plugin
export var component = "Teaser-module--component--MfbLW";
export var inner = "Teaser-module--inner--hX6WK";
export var badge = "Teaser-module--badge--4aeFQ";
export var content = "Teaser-module--content--mvOzr";
export var event = "Teaser-module--event--zc0Dc";
export var media = "Teaser-module--media--dY0Cf";
export var cta = "Teaser-module--cta--24KDs";
export var current = "Teaser-module--current--7HMaR";
export var label = "Teaser-module--label--Cb6XR";
export var link = "Teaser-module--link--DivP+";
export var icon = "Teaser-module--icon--5o-f1";
export var excerpt = "Teaser-module--excerpt--IkcK7";
export var contentInner = "Teaser-module--contentInner--tpyuZ";
export var header = "Teaser-module--header--FMemR";
export var space = "Teaser-module--space--X59Ui";
export var eventList = "Teaser-module--eventList--npx0K";
export var categories = "Teaser-module--categories--rrNRl";
export var category = "Teaser-module--category--g5dvI";
export var meta = "Teaser-module--meta--GjE2y";