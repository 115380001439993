import { getMostRelevantDate } from "@municipio/gatsby-theme-basic/src/utils";
import formatDate from "date-fns/format";
import formatISO from "date-fns/formatISO";

export function getMostRelevantEvents(occasions, rcrRules) {
  //get events from OCCASIONS
  const occasionsDates =
    occasions?.map((item) =>
      item.endDate
        ? item.startDate + "-" + item.endDate.slice(11, item.endDate?.length)
        : item.startDate,
    ) || [];

  //get events from recurrence RULES
  //get all the dates between start and end of rcr rule
  const getDateArray = function (start, end) {
    var arr = new Array();
    var dt = new Date(start);
    var en = new Date(end);
    while (dt <= en) {
      arr.push(new Date(dt).toString().substring(0, 15));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  };

  let rcrDates = [];
  if (rcrRules?.length > 0) {
    rcrRules.forEach((item, i) => {
      const startDate = rcrRules?.[i].rcrStartDate;
      const endDate = rcrRules?.[i].rcrEndDate;
      const weekDay = rcrRules?.[i].rcrWeekDay.substring(0, 3);
      const interval = rcrRules?.[i].rcrWeeklyInterval;

      const dateArray = getDateArray(startDate, endDate);
      //filter out only needed dates according to weekday in rule
      const filterByWeekDay = dateArray.filter((item) =>
        item.startsWith(weekDay),
      );

      const filterByInterval = filterByWeekDay.filter((item, index) => {
        return ((index) % interval === 0 ) ? item : '';
      });

      const formattedFilterByWeekDay = filterByInterval.map((item) =>
        formatDate(new Date(item), "y-MM-dd"),
      );
      const exceptionDays = rcrRules?.[i].rcrExceptions?.map(
        (item) => item.rcrExcDate,
      );
      //filter out exceptions in rule
      const withoutExceptionDays = formattedFilterByWeekDay.filter(
        (val) => !exceptionDays?.includes(val),
      );
      const withTimes = withoutExceptionDays.map((item) => {
        let endTime = "";
        if (rcrRules?.[i].rcrEndTime) {
          endTime = "-" + rcrRules?.[i].rcrEndTime;
        }
        return item + " " + rcrRules?.[i].rcrStartTime + endTime;
      });
      rcrDates.push(withTimes);
    });
  }
  rcrDates = rcrDates.flat();

  //find nearest event and number of rest upcoming events from all events(occasions + rcr)
  const eventDatesWithTime = [...occasionsDates, ...rcrDates]?.sort();
  const eventDates = eventDatesWithTime?.map((item) =>
    formatISO(new Date(item)),
  );
  const eventDate = eventDates?.length > 0 && getMostRelevantDate(eventDates);
  const formattedNearestDate = eventDate
    ? formatISO(new Date(eventDate))
    : null;
  const indexOfNearestDate = eventDates?.indexOf(formattedNearestDate);
  const eventsNumber = eventDates?.length - 1;
  const futureEventsCount = eventsNumber - indexOfNearestDate;
  const eventDateWithTime = eventDatesWithTime[indexOfNearestDate];

  return {
    eventDates,
    eventDate,
    futureEventsCount,
    eventDatesWithTime,
    eventDateWithTime,
  };
}
