import Image from "@whitespace/gatsby-theme-wordpress-basic/src/components/Image";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import * as defaultStyles from "./ArticleFeaturedImage.module.css";

ArticleFeaturedImage.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleFeaturedImage({
  styles = defaultStyles,
  className,
  ...restProps
}) {
  let {
    contentNode: { featuredImage },
    // isPreview,
  } = usePageContext();

  featuredImage = !!(featuredImage && featuredImage.node) && {
    ...featuredImage.node,
    width: "1368",
    height: "770",
  };

  if (!featuredImage) {
    return null;
  }
  return (
    <div className={clsx(styles.component, className)}>
      <Image
        className={clsx(styles.image, className)}
        {...featuredImage}
        {...restProps}
      />
    </div>
  );
}
